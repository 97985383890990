import React from 'react';
import { BsGithub, BsLinkedin, BsTwitter} from 'react-icons/bs';

const SocialMedia = () => {
  return (
    <div className='app__social'>
      {/* <div>
        <BsTwitter />
      </div> */}
      <a href='https://www.linkedin.com/in/agustin-carnevale-1254a6140/' target='_blank' rel='noreferrer'>
        <div>
          <BsLinkedin />
        </div>
      </a>
      <a href='https://github.com/agustin-carnevale' target='_blank' rel='noreferrer'>
        <div>
          <BsGithub />
        </div>
      </a>
    </div>
  );
}

export default SocialMedia;