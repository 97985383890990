import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { HiChevronRight, HiChevronLeft } from 'react-icons/hi';

import { urlFor, client } from '../../client';
import { AppWrap, MotionWrap } from '../../hoc';
import './Testimonials.scss';


const Testimonials = () => {
  const [brands, setBrands] = useState([])
  const [testimonials, setTestimonials] = useState([])
  const [currentIndex, setCurrentIndex] = useState(0)

  useEffect(() => {
    const queryBrands = '*[_type == "brands"]';
    const queryTestimonials = '*[_type == "testimonials"]';

    client.fetch(queryBrands).then((data)=> setBrands(data))
    client.fetch(queryTestimonials).then((data)=> setTestimonials(data))
  }, [])
  
  const handleClick = (index) => {
    setCurrentIndex(index);
  }

  console.log(testimonials)

  return (
    <>
      {testimonials.length > 0  && (
        <>
          <div className="app__testimonials-item app__flex">
            <img src={urlFor(testimonials[currentIndex].imgUrl)} alt={testimonials[currentIndex].name} />
            <div className="app__testimonials-content">
              <p className="p-text">{testimonials[currentIndex].feedback}</p>
              <div>
                <h4 className="bold-text">{testimonials[currentIndex].name}</h4>
                <h5 className="p-text">{testimonials[currentIndex].company}</h5>
              </div>
            </div>
          </div>

          <div className="app__testimonials-btns app__flex">
            <div className="app__flex" onClick={() => handleClick(currentIndex === 0 ? testimonials.length - 1 : currentIndex - 1)}>
              <HiChevronLeft />
            </div>

            <div className="app__flex" onClick={() => handleClick(currentIndex === testimonials.length - 1 ? 0 : currentIndex + 1)}>
              <HiChevronRight />
            </div>
          </div>
        </>
      )}

      <div className="app__testimonials-brands app__flex">
        {brands.map((brand) => (
          <motion.div
            whileInView={{ opacity: [0, 1] }}
            transition={{ duration: 0.5, type: 'tween' }}
            key={brand._id}
          >
            <img src={urlFor(brand.imgUrl)} alt={brand.name} />
          </motion.div>
        ))}
      </div>
    </>
  );
}


export default AppWrap(
  MotionWrap(Testimonials, 'app__testimonials'), 
  'testimonials',
  'app__primarybg'
);