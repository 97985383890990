import React, { useState } from 'react';

import { images } from '../../constants';
import { AppWrap, MotionWrap } from '../../hoc';
import { client } from '../../client';
import './Footer.scss';

const Footer = () => {

  const [formData, setFormData] = useState({
    name: '',
    email:'',
    message: '',
  })
  const [isFormSubmitted, setIsFormSubmitted] = useState(false)
  const [loading, setLoading] = useState(false)

  const handleInputChange = (e) => {
    const {name, value} = e.target;

    setFormData({...formData, [name]: value})
  }

  const handleSubmit = () => {
    setLoading(true)
    const contact = {
      _type: 'contact',
      name,
      email,
      message
    }

    client.create(contact)
      .then(() => {
        setIsFormSubmitted(true)
        setLoading(false)
      })
  }

  const { name, email, message } = formData;

  return (
    <>
      <h2 className='head-text'>Take a coffee & chat with me</h2>

      <div className='app__footer-cards'>
        <div className='app__footer-card'>
          <img src={images.email} alt='email' />
          <a href='mailto:agustinc.jobs@gmail.com' className='p-text'>agustinc.jobs@gmail.com</a>
        </div>
        {/* <div className='app__footer-card'>
          <img src={images.mobile} alt='mobile' />
          <a href='tel: +54 (911) 1111 2222' className='p-text'>+54 (911) 1111 2222</a>
        </div> */}
      </div>

      {!isFormSubmitted ? 
        <div className='app__footer-form app__flex'>
          <div className='app__flex'>
            <input className='p-text' type='text' name='name' placeholder='name' value={name} onChange={handleInputChange}/>
          </div>
          <div className='app__flex'>
            <input className='p-text' type='email' name='email' placeholder='your email' value={email} onChange={handleInputChange}/>
          </div>
          <div>
            <textarea  className='p-text' name='message' placeholder='your message' value={message} onChange={handleInputChange}/>
          </div>
          <button type='button' className='p-text' onClick={handleSubmit}>{ loading ? 'Sending' : 'Send message'}</button>
        </div>
        : <div>
          <h3 className='head-text'>Thank you for getting in touch</h3>
        </div>
      }
    </>
  );
}


export default AppWrap(
  MotionWrap(Footer, 'app__footer'), 
  'contact',
  'app__whitebg'
);